import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { DateTime } from 'luxon'; // Importa DateTime de luxon
import '../styles/clock.css';  // Importa o arquivo de estilos

const timezones = {
  'America/Sao_Paulo': 'Hora de Brasília (UTC-3)',
  'America/Recife': 'Horário de Recife (UTC-3)',
  'America/Noronha': 'Horário de Fernando de Noronha (UTC-2)',
  'America/Cuiaba': 'Horário de Cuiabá (UTC-4)',
  'America/Manaus': 'Horário de Manaus (UTC-4)',
  'America/Rio_Branco': 'Horário de Rio Branco (UTC-5)'
};

function App() {
  const [localTime, setLocalTime] = useState('');
  const [timezone, setTimezone] = useState('America/Sao_Paulo');
  const [menuVisible, setMenuVisible] = useState(false);
  const [title, setTitle] = useState(timezones[timezone]);

  const fetchServerTime = async (timezone) => {
    try {
      const response = await axios.get(`https://worldtimeapi.org/api/timezone/${timezone}`);
      const serverTime = DateTime.fromISO(response.data.datetime, { zone: 'utc' });
      const localTime = serverTime.setZone(timezone); // Converte para o fuso horário local
      setLocalTime(localTime.toLocaleString(DateTime.TIME_WITH_SECONDS));
    } catch (error) {
      console.error('Error fetching server time:', error);
    }
  };

  useEffect(() => {
    fetchServerTime(timezone);
    const intervalId = setInterval(() => fetchServerTime(timezone), 1000); // Atualiza a cada segundo

    return () => clearInterval(intervalId); // Limpa o intervalo ao desmontar o componente
  }, [timezone]);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const handleTimezoneChange = (newTimezone) => {
    setTimezone(newTimezone);
    setTitle(timezones[newTimezone]);
    setMenuVisible(false);
  };

  return (
    <div className="main-container">
      <div className="container">
        <div className="title-container" onClick={toggleMenu}>
          <h1>{title}</h1>
          <span className="arrow">▼</span>
          {menuVisible && (
            <div className="dropdown-menu">
              {Object.keys(timezones).map((tz) => (
                <div key={tz} onClick={() => handleTimezoneChange(tz)}>
                  {timezones[tz]}
                </div>
              ))}
            </div>
          )}
        </div>
        <p className="time">{localTime}</p>
      </div>
    </div>
  );
}

export default App;
