import React, { useState, useEffect } from "react";
import { FaTrash, FaSave } from "react-icons/fa";
import * as XLSX from "xlsx";
import "../styles/timer.css";
import fav from "../content/relogio.png";
import CronometroFullscreen from "../parts/CronometroFullscreen";


function Cronometro() {
  const [tempoInicial, setTempoInicial] = useState(0);
  const [tempoDecorrido, setTempoDecorrido] = useState(0);
  const [running, setRunning] = useState(false);
  const [voltasRegistradas, setVoltasRegistradas] = useState([]);
  const [historico, setHistorico] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedHistoricoOption, setSelectedHistoricoOption] = useState("");
  const [telaCheia, setTelaCheia] = useState(false);

  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'icon';
    link.href = fav; // Use a variável fav como caminho da imagem
    document.head.appendChild(link);
    
    return () => {
      // Remova o link quando o componente for desmontado (opcional)
      document.head.removeChild(link);
    };
  }, []);

  useEffect(() => {
    document.title = "Cronômetro"; // Definindo o título da página
    const metaDescription = document.createElement("meta");
    metaDescription.name = "cronometragem";
    metaDescription.content =
      "Cronometre seu tempo, conte voltas, salve suas medições, baixe as suas contagens, tudo isso numa interface limpa e intuitiva";
    document.head.appendChild(metaDescription);

    // Removendo a tag de descrição ao desmontar o componente para evitar vazamentos de memória
    return () => {
      document.head.removeChild(metaDescription);
    };
  }, []);

  const handleHistoricoOption = (option) => {
    setSelectedHistoricoOption(option);
  };

  useEffect(() => {
    let timer;
    if (running) {
      timer = setInterval(() => {
        const tempoAtual = Date.now();
        setTempoDecorrido(tempoAtual - tempoInicial);
      }, 10);
    } else {
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [running, tempoInicial]);

  useEffect(() => {
    const savedVoltasRegistradas = localStorage.getItem("voltasRegistradas");
    if (savedVoltasRegistradas) {
      setVoltasRegistradas(JSON.parse(savedVoltasRegistradas));
    }
  }, []);

  useEffect(() => {
    const savedHistorico = localStorage.getItem("historico");
    const savedVoltasRegistradas = localStorage.getItem("voltasRegistradas");

    if (savedHistorico) {
      setHistorico(
        JSON.parse(savedHistorico).map((item) => ({
          ...item,
          timestamp: new Date(item.timestamp),
        }))
      );
    }

    if (savedVoltasRegistradas) {
      const voltas = JSON.parse(savedVoltasRegistradas);
      if (voltas.length > 0) {
        setHistorico((prevHistorico) => [
          ...prevHistorico,
          { timestamp: new Date(), voltas },
        ]);
        localStorage.removeItem("voltasRegistradas");
      }
    }
  }, []);

  useEffect(() => {
    if (voltasRegistradas.length > 0) {
      localStorage.setItem(
        "voltasRegistradas",
        JSON.stringify(voltasRegistradas)
      );
    } else {
      localStorage.removeItem("voltasRegistradas");
    }
  }, [voltasRegistradas]);

  useEffect(() => {
    if (historico.length > 0) {
      localStorage.setItem("historico", JSON.stringify(historico));
    } else {
      localStorage.removeItem("historico");
    }
  }, [historico]);

  useEffect(() => {
    setVoltasRegistradas([]);
  }, []);

  const handleStart = () => {
    if (!running) {
      const tempoAtual = Date.now();
      setTempoInicial(tempoAtual - tempoDecorrido);
    }
    setRunning(true);
  };

  const handleStop = () => {
    setRunning(false);
  };

  const handleReset = () => {
    setRunning(false);
    setTempoInicial(0);
    setTempoDecorrido(0);
    setVoltasRegistradas([]);
  };

  const handleVolta = () => {
    const tempoVolta = tempoDecorrido;
    const formattedVolta = formatTime(tempoVolta);
    setVoltasRegistradas([...voltasRegistradas, formattedVolta]);
  };

  const formatTime = (time) => {
    const centiseconds = Math.floor(time / 10) % 100;
    const seconds = Math.floor(time / 1000) % 60;
    const minutes = Math.floor(time / (1000 * 60)) % 60;
    const hours = Math.floor(time / (1000 * 60 * 60));
    return `${hours < 10 ? `0${hours}` : hours}:${
      minutes < 10 ? `0${minutes}` : minutes
    }:${seconds < 10 ? `0${seconds}` : seconds}:${
      centiseconds < 10 ? `0${centiseconds}` : centiseconds
    }`;
  };

  const handleSaveOption = (option) => {
    setSelectedOption(option);
  };

  const downloadVoltas = () => {
    const element = document.createElement("a");
    let file;

    if (selectedOption === "txt") {
      file = new Blob([voltasRegistradas.join("\n")], { type: "text/plain" });
      element.download = "voltas.txt";
    } else if (selectedOption === "xlsx") {
      const worksheet = XLSX.utils.aoa_to_sheet([
        ["Voltas Registradas"],
        ...voltasRegistradas.map((volta, index) => [
          `Volta ${index + 1}`,
          volta,
        ]),
      ]);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Voltas");
      file = new Blob(
        [XLSX.write(workbook, { type: "array", bookType: "xlsx" })],
        {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }
      );
      element.download = "voltas.xlsx";
    }

    element.href = URL.createObjectURL(file);
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const toggleFullScreen = () => {
    setTelaCheia(!telaCheia);
  };

  const clearHistorico = () => {
    setHistorico([]);
    localStorage.removeItem("historico");
  };

  const downloadHistorico = (format) => {
    let file;
    const element = document.createElement("a");

    if (format === "txt") {
      const formattedHistorico = historico
        .map((item) => {
          const timestamp = item.timestamp.toLocaleString();
          const voltas = item.voltas
            .map((volta, index) => `Volta ${index + 1}: ${volta}`)
            .join("\n");
          return `${timestamp}\n${voltas}\n`;
        })
        .join("\n");

      file = new Blob([formattedHistorico], { type: "text/plain" });
      element.download = "historico.txt";
    } else if (format === "xlsx") {
      const maxVoltas = historico.reduce(
        (max, item) => Math.max(max, item.voltas.length),
        0
      );
      const voltaHeaders = Array.from(
        { length: maxVoltas },
        (_, index) => `Volta ${index + 1}`
      );
      const data = historico.map((item) => [
        item.timestamp.toLocaleString(),
        ...item.voltas,
      ]);

      const worksheet = XLSX.utils.aoa_to_sheet([
        ["Timestamp", ...voltaHeaders],
        ...data,
      ]);

      const lengths = [];
      [voltaHeaders, ...data].forEach((row) => {
        row.forEach((cell, i) => {
          lengths[i] = Math.max(lengths[i] || 0, cell.toString().length);
        });
      });
      worksheet["!cols"] = lengths.map((len) => ({ wch: len }));

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Historico");
      file = new Blob(
        [XLSX.write(workbook, { bookType: "xlsx", type: "array" })],
        {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }
      );
      element.download = "historico.xlsx";
    }

    element.href = URL.createObjectURL(file);
    document.body.appendChild(element);
    element.click();
  };

  const handleDownload = () => {
    if (selectedOption) {
      downloadVoltas();
    }
  };

  const handleHistoricoDownload = () => {
    if (selectedHistoricoOption) {
      downloadHistorico(selectedHistoricoOption);
    }
  };

  return (
    <div className="cronometro-container">
      {telaCheia ? (
        <CronometroFullscreen
          tempoDecorrido={tempoDecorrido}
          formatTime={formatTime}
        />
      ) : (
        <>
          <h1 className="cron">Cronômetro</h1>
          <div className="cronometro-display">
            <span>{formatTime(tempoDecorrido)}</span>
          </div>
          <div className="cronometro-buttons">
            <button
              className="start-button"
              onClick={handleStart}
              disabled={running}
            >
              Iniciar
            </button>
            <button
              className="stop-button"
              onClick={handleStop}
              disabled={!running}
            >
              Parar
            </button>
            <button className="volta-button" onClick={handleVolta}>
              Registrar Volta
            </button>
            <button className="reset-button" onClick={handleReset}>
              Resetar
            </button>
            {/* <button className="fullscreen-button" onClick={toggleFullScreen}>
              {telaCheia ? "Sair da Tela Cheia" : "Tela Cheia"}
            </button> */}
            <div className="dropdown">
              <button className="save-button">Salvar</button>
              <div className="dropdown-content">
                <button
                  onClick={() => {
                    handleSaveOption("txt");
                    handleDownload();
                  }}
                >
                  Salvar como TXT
                </button>
                <button
                  onClick={() => {
                    handleSaveOption("xlsx");
                    handleDownload();
                  }}
                >
                  Salvar como XLSX
                </button>
              </div>
            </div>
          </div>
          <div
            className={`voltas-registradas ${
              voltasRegistradas.length > 0 ? "show" : ""
            }`}
          >
            <h2>Voltas Registradas</h2>
            <ul>
              {voltasRegistradas.map((volta, index) => (
                <li key={index}>{`Volta ${index + 1}: ${volta}`}</li>
              ))}
            </ul>
          </div>
          <div
            className={`historico-cronometragens ${
              historico.length > 0 ? "show" : ""
            }`}
          >
            <div className="historico-header">
              <h2>Histórico de Cronometragens</h2>
              <div className="icon-save">
                <FaSave className="icon" title="Salvar Histórico" />
                <div className="dropdown-content">
                  <button
                    onClick={() => {
                      handleHistoricoOption("txt");
                      handleHistoricoDownload();
                    }}
                  >
                    Salvar como TXT
                  </button>
                  <button
                    onClick={() => {
                      handleHistoricoOption("xlsx");
                      handleHistoricoDownload();
                    }}
                  >
                    Salvar como XLSX
                  </button>
                </div>
              </div>

              <FaTrash
                className="icon"
                onClick={clearHistorico}
                title="Limpar Histórico"
              />
            </div>
            <ul>
              {historico.map((item, index) => (
                <li key={index}>
                  <strong>{`Cronometragem ${
                    index + 1
                  } - ${item.timestamp.toLocaleString()}`}</strong>
                  <ul>
                    {item.voltas.map((volta, voltaIndex) => (
                      <li key={voltaIndex}>{`Volta ${
                        voltaIndex + 1
                      }: ${volta}`}</li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
}

export default Cronometro;
