// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './parts/header.js';
import Home from './pages/main.js';
import Timer from './pages/timer'; // Importe o componente Timer
import Clock from './pages/clock';
import Target from './pages/target';
import Countdown from './pages/countdown';
import Sorteador from './pages/sorteador';


function App() {
  return (
    <Router>
    <div>
      <Header />
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/countdown" element={<Countdown />} />
        <Route path="/timer" element={<Timer />} />
        <Route path="/clock" element={<Clock />} />
        <Route path="/target" element={<Target />} />
        <Route path="/sorteador" element={<Sorteador />} />
        {/* Outras rotas */}
      </Routes>
    </div>
  </Router>
  );
}

export default App;
