import React from 'react';
import '../styles/sidebar.css';

function Sidebar({ isOpen, onClose }) {
  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <button className="close-button" onClick={onClose}>X</button>
      <nav>
        <ul>
          <li><a href="/timer">Cronômetro</a></li>
          <li><a href="/clock">Horário Real</a></li>
          <li><a href="/target">Tempo restante</a></li>
          <li><a href="/countdown">Contagem Regressiva</a></li>
          <li><a href="/sorteador">Sorteador</a></li>
          {/* <li><a href="#">Sobre</a></li>
          <li><a href="#">Contato</a></li> */}
        </ul>
      </nav>
    </div>
  );
}

export default Sidebar;
