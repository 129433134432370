import React from "react";
import "../styles/CronometroFullscreen.css";

function CronometroFullscreen({ tempoDecorrido, formatTime }) {
  return (
    <div className="cronometro-fullscreen-container">
      <div className="cronometro-fullscreen-display">
        <span>{formatTime(tempoDecorrido)}</span>
      </div>
    </div>
  );
}

export default CronometroFullscreen;
