import React, { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import "../styles/target.css";

const Target = () => {
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const [seconds, setSeconds] = useState("");
  const [loading, setLoading] = useState(false);
  const [targetDate, setTargetDate] = useState(null);
  const [timeLeft, setTimeLeft] = useState({});

  const isValidTime = () => {
    const currentDate = new Date();
    const targetYear = year ? parseInt(year) : currentDate.getFullYear();
    const targetMonth = month ? parseInt(month) - 1 : currentDate.getMonth();
    const targetDay = day ? parseInt(day) : currentDate.getDate();
    const targetHours = hours ? parseInt(hours) : 0;
    const targetMinutes = minutes ? parseInt(minutes) : 0;
    const targetSeconds = seconds ? parseInt(seconds) : 0;

    const newTargetDate = new Date(targetYear, targetMonth, targetDay, targetHours, targetMinutes, targetSeconds);

    if (newTargetDate < currentDate) {
      return false;
    }

    return true;
  };

  const handleSetTargetDate = () => {
    if (!isValidTime()) {
      alert("Insira um tempo válido para começar.");
      return;
    }

    setLoading(true);

    const currentDate = new Date();
    const targetYear = year ? parseInt(year) : currentDate.getFullYear();
    const targetMonth = month ? parseInt(month) - 1 : currentDate.getMonth();
    const targetDay = day ? parseInt(day) : currentDate.getDate();
    const targetHours = hours ? parseInt(hours) : 0;
    const targetMinutes = minutes ? parseInt(minutes) : 0;
    const targetSeconds = seconds ? parseInt(seconds) : 0;

    const newTargetDate = new Date(targetYear, targetMonth, targetDay, targetHours, targetMinutes, targetSeconds);
    setTargetDate(newTargetDate);
  };

  const calculateTimeLeft = (targetDate) => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  useEffect(() => {
    if (targetDate) {
      setLoading(true);

      setTimeout(() => {
        setTimeLeft(calculateTimeLeft(targetDate));
        setLoading(false);
      }, 1000);
    }
  }, [targetDate]);

  useEffect(() => {
    if (targetDate) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(targetDate));
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [timeLeft, targetDate]);

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span key={interval}>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  return (
    <div className="outer-container">
      <div className="countdown-container">
        <h1 className="countdown-title">Tempo Restante</h1>
        <div className="input-container">
          <input
            type="number"
            placeholder="Ano"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            className="date-input"
          />
          <span className="separator">/</span>
          <input
            type="number"
            placeholder="Mês"
            value={month}
            onChange={(e) => setMonth(e.target.value)}
            className="date-input"
          />
          <span className="separator">/</span>
          <input
            type="number"
            placeholder="Dia"
            value={day}
            onChange={(e) => setDay(e.target.value)}
            className="date-input"
          />
          <input
            type="number"
            placeholder="Horas"
            value={hours}
            onChange={(e) => setHours(e.target.value)}
            className="date-input"
          />
          <span className="separator">:</span>
          <input
            type="number"
            placeholder="Minutos"
            value={minutes}
            onChange={(e) => setMinutes(e.target.value)}
            className="date-input"
          />
          <span className="separator">:</span>
          <input
            type="number"
            placeholder="Segundos"
            value={seconds}
            onChange={(e) => setSeconds(e.target.value)}
            className="date-input"
          />
          <button onClick={handleSetTargetDate} className="set-target-button">
            Definir Data Alvo
          </button>
        </div>
        <div className="countdown-timer">
          {loading ? (
            <TailSpin color="#000" height={50} width={50} />
          ) : targetDate ? (
            timerComponents.length ? timerComponents : <span className="countdown-expired">Acabou o tempo!</span>
          ) : (
            <span className="countdown-warning">Insira um tempo para começar</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Target;
