// Button.js
import React from 'react';

const Button = ({ label, subtitle, onClick }) => {
  return (
    <button className="grid-button" onClick={onClick}>
      <div className="button-content">
        <div className="button-label">{label}</div>
        <div className="button-subtitle">{subtitle}</div>
      </div>
    </button>
  );
};

export default Button;
