// HomePage.js
import React from 'react';
import Button from '../parts/Button';
import "../styles/main.css";

const HomePage = () => {
  const buttons = [
    { label: 'Tempo Real', subtitle: 'Tenha o horário real, simples e preciso, de vários fusos horários do Brasil', link: '/clock' },
    { label: 'Cronômetro', subtitle: 'Cronometre, Dê voltas e Armazene, de um jeito simples e eficiente', link: '/timer' },
    { label: 'Tempo Restante', subtitle: 'Faça uma contagem regressiva a partir de uma data, mês ou hora específicos', link: '/target' },
    { label: 'Contagem Regressiva', subtitle: 'Faça contagens regressivas a partir de um tempo específico', link: '/countdown' },
    { label: 'Sorteador', subtitle: 'Faça sorteios de números, nomes ou textos aleatórios', link: '/sorteador' },
  ];

  const handleButtonClick = (link) => {
    window.location.href = link; // Redireciona para o URL especificado
  };

  return (
    <div>
      <h2 className="title">Ferramentas Gratuitas Online</h2>
      <div className="grid-container">
        {buttons.map((button, index) => (
          <Button key={index} label={button.label} subtitle={button.subtitle} onClick={() => handleButtonClick(button.link)} />
        ))}
      </div>
    </div>
  );
};

export default HomePage;
