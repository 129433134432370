import React, { useState, useEffect } from 'react';
import '../styles/countdown.css';

const CountdownTimer = () => {
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');
  const [seconds, setSeconds] = useState('');
  const [timeLeft, setTimeLeft] = useState(null);
  const [isRunning, setIsRunning] = useState(false);
  const [laps, setLaps] = useState([]);
  const [inputError, setInputError] = useState(false);

  useEffect(() => {
    let timer;
    if (isRunning && timeLeft > 0) {
      timer = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
    } else if (timeLeft === 0) {
      setIsRunning(false);
    }
    return () => clearTimeout(timer);
  }, [isRunning, timeLeft]);

  const startTimer = () => {
    if (hours.trim() === '' && minutes.trim() === '' && seconds.trim() === '') {
      setInputError(true);
      return;
    }
    setInputError(false);
    setLaps([]); // Limpa as voltas registradas
    const totalSeconds = (parseInt(hours) || 0) * 3600 + (parseInt(minutes) || 0) * 60 + (parseInt(seconds) || 0);
    setTimeLeft(totalSeconds);
    setIsRunning(true);
  };

  const recordLap = () => {
    if (isRunning && timeLeft !== null) {
      setLaps([...laps, formatTime(timeLeft)]);
    }
  };

  const formatTime = (seconds) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = seconds % 60;
    return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
  };

  return (
    <div>
      <div className="container-count">
        <h1 className="title-count">Contagem Regressiva</h1>
        <div className="input-group">
          <input
            type="number"
            className="input"
            value={hours}
            onChange={(e) => setHours(e.target.value)}
            placeholder="HH"
          />
          <span className="separator">:</span>
          <input
            type="number"
            className="input"
            value={minutes}
            onChange={(e) => setMinutes(e.target.value)}
            placeholder="MM"
          />
          <span className="separator">:</span>
          <input
            type="number"
            className="input"
            value={seconds}
            onChange={(e) => setSeconds(e.target.value)}
            placeholder="SS"
          />
        </div>
        <div className="button-group">
          <button className="button" onClick={startTimer}>Iniciar</button>
          <button className="button" onClick={recordLap} disabled={!isRunning}>Registrar Volta</button>
        </div>
        {inputError && (
          <div className="input-error">Por favor, insira valores nos campos</div>
        )}
        {timeLeft !== null && (
          <div className={`time-display ${timeLeft === 0 ? 'time-ended' : ''}`}>
            {timeLeft > 0 ? formatTime(timeLeft) : 'O tempo acabou'}
          </div>
        )}
      </div>
      {laps.length > 0 && (
        <div className="container-laps">
          <h2 className="title-laps">Voltas Registradas</h2>
          <div className="laps-container">
            {laps.map((lap, index) => (
              <div key={index} className="lap">
                Volta {index + 1}: {lap}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CountdownTimer;
