import React, { useState } from 'react';
import Sidebar from './Sidebar';
import logo from '../content/logo.png';
import '../styles/header.css';

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const redirectToPage = () => {
    // Substitua "/pagina-de-destino" pelo URL real da página desejada
    window.location.href = "/";
  };

  return (
    <header>
      <div className="menu-icon" onClick={toggleMenu}>
        ☰
      </div>
      <img src={logo} alt="Logo" onClick={redirectToPage} style={{ cursor: 'pointer' }} />
      <Sidebar isOpen={menuOpen} onClose={closeMenu} />
    </header>
  );
}

export default Header;
