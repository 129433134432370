import React, { useState } from 'react';
import '../styles/sorteador.css';

const SorteadorPage = () => {
  const [simpleDrawResult, setSimpleDrawResult] = useState(null);
  const [randomNumber, setRandomNumber] = useState(null);
  const [intervals, setIntervals] = useState([]);
  const [currentInterval, setCurrentInterval] = useState({ min: '', max: '' });
  const [texts, setTexts] = useState([]);
  const [newText, setNewText] = useState('');
  const [textDrawResult, setTextDrawResult] = useState(null);
  const [noReplacementNumbers, setNoReplacementNumbers] = useState(false);
  const [noReplacementTexts, setNoReplacementTexts] = useState(false);
  const [usedNumbers, setUsedNumbers] = useState([]);
  const [usedTexts, setUsedTexts] = useState([]);

  const handleSimpleDraw = () => {
    const result = Math.floor(Math.random() * 10) + 1;
    setSimpleDrawResult(result);
  };

  const handleRandomNumber = () => {
    if (intervals.length === 0) {
      setRandomNumber('Adicione intervalos primeiro');
      return;
    }

    const availableNumbers = intervals.flatMap(interval =>
      Array.from({ length: interval.max - interval.min + 1 }, (_, i) => interval.min + i)
    );

    if (noReplacementNumbers) {
      const filteredNumbers = availableNumbers.filter(number => !usedNumbers.includes(number));
      if (filteredNumbers.length === 0) {
        setRandomNumber('Todos os números foram sorteados');
        return;
      }
      const randomIndex = Math.floor(Math.random() * filteredNumbers.length);
      const result = filteredNumbers[randomIndex];
      setUsedNumbers([...usedNumbers, result]);
      setRandomNumber(result);
    } else {
      const totalSize = availableNumbers.length;
      const randomPoint = Math.floor(Math.random() * totalSize);
      const result = availableNumbers[randomPoint];
      setRandomNumber(result);
    }
  };

  const addInterval = () => {
    const min = parseInt(currentInterval.min, 10);
    const max = parseInt(currentInterval.max, 10);

    if (isNaN(min) || isNaN(max) || min >= max) {
      alert('Intervalo inválido. Certifique-se de que min e max são números e que min < max.');
      return;
    }

    setIntervals([...intervals, { min, max }]);
    setCurrentInterval({ min: '', max: '' });
  };

  const removeInterval = (index) => {
    setIntervals(intervals.filter((_, i) => i !== index));
  };

  const addText = () => {
    if (newText.trim() === '') {
      alert('Texto não pode estar vazio.');
      return;
    }

    setTexts([...texts, newText]);
    setNewText('');
  };

  const removeText = (index) => {
    setTexts(texts.filter((_, i) => i !== index));
  };

  const handleTextDraw = () => {
    if (texts.length === 0) {
      setTextDrawResult('Adicione textos primeiro');
      return;
    }

    if (noReplacementTexts) {
      const filteredTexts = texts.filter(text => !usedTexts.includes(text));
      if (filteredTexts.length === 0) {
        setTextDrawResult('Todos os textos foram sorteados');
        return;
      }
      const randomIndex = Math.floor(Math.random() * filteredTexts.length);
      const result = filteredTexts[randomIndex];
      setUsedTexts([...usedTexts, result]);
      setTextDrawResult(result);
    } else {
      const randomIndex = Math.floor(Math.random() * texts.length);
      const result = texts[randomIndex];
      setTextDrawResult(result);
    }
  };

  return (
    <div>
      <div className="sorteador-container">
        <h2 className="sorteador-h2">Sorteio de Números</h2>
        <div className="sorteador-input-container">
          <input
            type="number"
            className="sorteador-input"
            placeholder="Mínimo"
            value={currentInterval.min}
            onChange={(e) => setCurrentInterval({ ...currentInterval, min: e.target.value })}
          />
          <input
            type="number"
            className="sorteador-input"
            placeholder="Máximo"
            value={currentInterval.max}
            onChange={(e) => setCurrentInterval({ ...currentInterval, max: e.target.value })}
          />
          <button className="sorteador-button" onClick={addInterval}>Adicionar Intervalo</button>
        </div>
        <div>
          <h3 className="sorteador-h3">Intervalos Adicionados</h3>
          <ul className="sorteador-ul">
            {intervals.map((interval, index) => (
              <li className="sorteador-li" key={index}>
                {interval.min} a {interval.max}
                <button className="sorteador-remove-btn" onClick={() => removeInterval(index)}>X</button>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <label>
            <input
              type="checkbox"
              className="sorteador-checkbox"
              checked={noReplacementNumbers}
              onChange={(e) => setNoReplacementNumbers(e.target.checked)}
            />
            Não repor números sorteados
          </label>
        </div>
        <button className="sorteador-button" onClick={handleRandomNumber}>Gerar Número Aleatório</button>
        {randomNumber !== null && <p className="sorteador-p">Número Aleatório: {randomNumber}</p>}
      </div>
      <div className="sorteador-container">
        <h2 className="sorteador-h2">Sorteio de Textos ou Nomes</h2>
        <div className="sorteador-input-container">
          <input
            type="text"
            className="sorteador-input"
            placeholder="Digite um texto"
            value={newText}
            onChange={(e) => setNewText(e.target.value)}
          />
          <button className="sorteador-button" onClick={addText}>Adicionar Texto</button>
        </div>
        <div>
          <h3 className="sorteador-h3">Textos Adicionados</h3>
          <ul className="sorteador-ul">
            {texts.map((text, index) => (
              <li className="sorteador-li" key={index}>
                {text}
                <button className="sorteador-remove-btn" onClick={() => removeText(index)}>X</button>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <label>
            <input
              type="checkbox"
              className="sorteador-checkbox"
              checked={noReplacementTexts}
              onChange={(e) => setNoReplacementTexts(e.target.checked)}
            />
            Não repor textos sorteados
          </label>
        </div>
        <button className="sorteador-button" onClick={handleTextDraw}>Sortear Texto</button>
        {textDrawResult !== null && <p className="sorteador-p">Texto Sorteado: {textDrawResult}</p>}
      </div>
    </div>
  );
};

export default SorteadorPage;
